.configurator-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;

}

.configurator-modal.open {
  display: flex;
}



/* TopViewModal.css */

.top-view-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.top-view-modal.open {
  display: flex;
}

.modal-content {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #EFECE2;

  padding: 40px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1220px;
  width: 100%;

}


#close {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.topView {
  position: relative;

  display: flex;
  flex-direction: row;
  padding: 60px 60px 60px 60px;
  align-items: flex-start;
  justify-content: flex-start;
}

.topModuliContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
}


.topModuli {
  width: 100%;
  position: relative;

}

.topCountertop {
  position: absolute;
  width: 100%;
  height: 95%;
  object-fit: cover;
  top: 2.5%;
  left: 0;

}


.heightLineWrapperTop {
  display: flex;
  flex-direction: row;
  width: fit-content;
  justify-content: space-between;
  align-self: stretch;
  padding-right: 15px;
}

.heightLineOrientation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100% ;
}

.elementsContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: fit-content;
  margin-top: 35px;
  border-radius: 1px;
  
}

 .heightLine {
  width: 2px;
  height: 100% ;
  background: #808080;

}

.heightLineNumbers {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.heightLineOrientation .segStart,
.heightLineOrientation .segEnd {
  width: 12px;
  height: 2px;
  background: #808080;
}

