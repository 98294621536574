.languageSwitch {
  position: absolute;
  top: 1%;
  right: 1%;
  background: #000;
  padding: 15px;
  color: #efece2;
  border-radius: 9999px;
  cursor: pointer;
}

.languageSwitch:hover {
  background-color: #F49800;
  color: #000;
  font-weight: 600;
}

.blacklogo {
  -webkit-filter: invert(100%);
  /* Safari/Chrome */
  filter: invert(100%);
}

.colorPicker {
  display: flex;
  padding: 7.6% 0px;
  margin: auto 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 70px;
  background: linear-gradient(0deg, #efece2 0%, #efece2 100%),
    var(--White, #efece2);
    height: 100vh;
}

.getStarted.disabled {
  /* Example styling for disabled state */
  opacity: 0.5;
  cursor: not-allowed;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.content h3 {
  color: var(--Cod-Gray, #121212);

  /* Heading 3 */
  font-family: Bebas Neue;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px;
  /* 120% */
  width: 100%;
}

.cabinets {
  width: 100%;
  margin-bottom: 40px;
}

.number {
  color: var(--Cod-Gray, #121212);
  text-align: center;

  /* Heading 6 */
  font-family: Work Sans Bold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  padding: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 1px solid #e4e4e4;
}

.warningMessage {
  color: var(--Orange, var(--Orange-Peel, #F49800));
  font-family: "Work Sans";
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.625rem;
  margin-top: -50px;
}

.colorWrapper {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4%;
  justify-content: center;
}

.cabinetText {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.cabinetsPicker {
  width: 100%;
  max-width: 580px;
}

.countertops {
  max-width: 580px;
  width: 100%;
}

.loadButton {
  display: inline-flex;
  padding: 10px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid var(--Lines, #A19C95);
  color: var(--Dark-Background, #0A0A0A);
  text-align: center;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  text-transform: uppercase;
}

.cabinetTitle {
  color: var(--Cod-Gray, #121212);

  /* Heading 6 */
  font-family: Work Sans Bold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.colors {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;

  /* flex-wrap: wrap; */
}

.colors p {
  color: var(--Tundora, #4d4d4d);

  /* Paragraph */
  font-family: Work Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  width: 100px;
  text-align: center;
  /* max-width: 70px; */
}

.pickerButtons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  width: 100%;
}

.getStarted {
  display: inline-flex;
  padding: 10px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: var(--Cod-Gray, #121212);
  color: var(--White, #FFF);
  text-align: center;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}

a {
  text-decoration: none;
}

.color {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 0px 15px; */
  gap: 6px;
  /* width: calc(25% - 15px); */
}

.color img {
  width: 90px;
  height: 90px;
  border: 4px solid transparent;
  transition: border 0.3s ease-in;
  border-radius: 10px;
}

.color.selected img {
  border: 4px solid #121212;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .blacklogo {
    -webkit-filter: invert(100%);
    /* Safari/Chrome */
    filter: invert(100%);
  }

  .colorPicker {
    display: flex;
    padding: 60px 20px;
    margin: auto 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 70px;
    height: unset;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .content h3 {
    color: var(--Cod-Gray, #121212);

    /* Heading 3 */
    font-family: Bebas Neue;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    /* 120% */
    width: 100%;
  }

  .cabinets {
    width: 100%;
    margin-bottom: 40px;
  }

  .number {
    color: var(--Cod-Gray, #121212);
    text-align: center;

    /* Heading 6 */
    font-family: Work Sans Bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    padding: 4px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 1px solid #e4e4e4;
  }

  .colorWrapper {
    width: 100%;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .cabinetText {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
  }

  .cabinetsPicker {
    width: 100%;
  }

  .cabinetTitle {
    color: var(--Cod-Gray, #121212);

    /* Heading 6 */
    font-family: Work Sans Bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
  }

  .colors {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    width: 100%;
    gap: 10px;
    /* flex-wrap: wrap; */
  }

  .colors p {
    color: var(--Tundora, #4d4d4d);

    /* Paragraph */
    font-family: Work Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 173.333% */
  }

  .getStarted {
    color: var(--White, #fff);
    text-align: center;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    text-transform: uppercase;
    border-radius: 50px;
    background: var(--Cod-Gray, #121212);
    padding: 10px 40px;
    text-decoration: none;
  }

  .color {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }

  .color img {
    width: 80px;
    height: 80px;
    border: 4px solid transparent;
    transition: border 0.3s ease-in;
    border-radius: 10px;
  }

  .color.selected img {
    border: 4px solid #121212;
    border-radius: 10px;
  }
}