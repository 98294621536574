@font-face {
  font-family: "Work Sans";
  src: url("/src/assets/fonts/WorkSansRegular.ttf");
}
@font-face {
  font-family: "Work Sans Bold";
  src: url("/src/assets/fonts/WKSB.ttf");
}
  @font-face {
  font-family: "Bebas Neue";
  src: url("/src/assets/fonts/bebas.ttf");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Work Sans";
}
body {
  background: var(--Dark-Background, #fff);
}
