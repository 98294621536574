/* PartsListModal.css */

.parts-list-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  padding: 25px;
  z-index: 99999;

}

.parts-list-modal.open {
  display: flex;
}

.modal-content-parts {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #EFECE2;
  padding: 40px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1220px;
  width: 100%;
}

#close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.partsList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.partItem {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
}

.partItem p {
  margin: 0;
  font-weight: bold;
}

/* Add additional styling as needed */
/* PartsListModal.css */

/* ... (previous styles) */

.table-container {
  max-height: 300px;
  /* Adjust the max-height based on your design */
  overflow-y: auto;
  overflow-x: auto ;
  width: 100%;
}

.partsTable {
  width: 100%;
  border-collapse: collapse;

}

.partsTable th,
.partsTable td {
  padding: 8px;
  text-align: left;
  color: var(--Tundora, #4D4D4D);

  /* Paragraph */
  font-family: Work Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 173.333% */
  border-bottom: 1px solid var(--Divider-Color, #E4E4E4);

}

.partsTable th {
  color: var(--Cod-Gray, #121212);

  /* Heading 6 */
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 200px;
  /* 150% */
}

/* Add additional styling as needed */
.tableContent {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: flex-start;
}
.title{
  text-align: left;
}
@media screen and (max-width: 768px) {
  /* PartsListModal.css */

  .parts-list-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    padding: 25px;
    z-index: 99999;

  }

  .parts-list-modal.open {
    display: flex;
  }

  .modal-content-parts {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #EFECE2;
    padding: 40px;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1220px;
    width: 100%;
  }

  #close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .partsList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
  }

  .partItem {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
  }

  .partItem p {
    margin: 0;
    font-weight: bold;
  }


  .table-container {
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: scroll !important;

  }

  .partsTable {
    width: 100%;
    border-collapse: collapse;
  }

  .table-container {}

  .partsTable th,
  .partsTable td {
    padding: 8px;
    text-align: left;
    color: var(--Tundora, #4D4D4D);

    font-family: Work Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    border-bottom: 1px solid var(--Divider-Color, #E4E4E4);

  }

  .partsTable th {
    color: var(--Cod-Gray, #121212);

    /* Heading 6 */
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    width: 100%;
    /* 150% */
  }

  /* Add additional styling as needed */
  .tableContent {
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: flex-start;
    width: 100%;
  }
}