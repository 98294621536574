.configuratorModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;

}

.configuratorModalContent {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #EFECE2;

  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 180px;
  align-items: flex-end;

}

.configuratorModalNavigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 228px;

}

.configuratorModalNavItem {
  display: flex;
  padding: 17px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
  width: 1020px;
  border-bottom: 1px solid var(--Divider-Color, #E4E4E4);
}
.configuratorModalNavItem:hover {
 cursor: pointer;
}


.configuratorModalNavItem p {
  color: var(--Cod-Gray, #121212);

  /* Heading 6 */
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.configuratorModalNavItem img {
  width: 30px;
  height: 30px;
  
  
}
.configuratorModalNavItem:hover img {
  filter: brightness(0) saturate(100%) hue-rotate(180deg) sepia(100%) saturate(1000%) hue-rotate(8deg) brightness(90%);
}


.configuratorModalHidden {
  display: none;
}

.dealerCodeContainer {
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;

}

.dealer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.dealerCodeContent {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
}

.dealerCodeText {
  width: 400px;
  color: var(--Tundora, #4D4D4D);
  text-align: center;
  align-self: stretch;
  font-family: Work Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.generateDealerCode {
  color: var(--Cod-Gray, #121212);
  text-align: center;

  /* Heading 4 */
  font-family: Bebas Neue;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 125% */
  letter-spacing: 0.48px;
}

.configuratorModalNavItem:hover p {
  color: var(--Orange-Peel, #F49800);

}

.dealerCodeText span {
  color: var(--Orange-Peel, #F49800);
  text-align: center;

  /* Paragraph */
  font-family: Work Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 10px;
  /* 173.333% */
}

.dealerCode {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;

}

.code {
  display: flex;
  padding: 12px 60px;
  align-items: center;
  gap: 10px;
  color: var(--White, #FFF);
  font-family: Bebas Neue;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 100% */
  letter-spacing: 0.6px;
  border-radius: 40px;
  background: var(--Orange-Peel, #F49800);
  cursor: pointer;
}

.code:hover {
  background: #d68400;
}

.copy {
  color: var(--Tundora, #4D4D4D);
  text-align: center;
  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  cursor: pointer;

  /* 100% */
}

.codeForm {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.title {
  color: var(--Cod-Gray, #121212);
  text-align: center;

  /* Heading 4 */
  font-family: Bebas Neue;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 125% */
  letter-spacing: 0.48px;
  margin-top: 30px;
}

.dealerCodeFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 60px;

}

.dealerCodeFormContainer input {
  display: flex;
  padding: 10px 23px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 40px;
  border: 1px solid var(--Divider-Color, #E4E4E4);
  margin-top: -15px;
}

.dealerCodeFormContainer button {
  display: flex;
  padding: 14px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 50px;
  background: #F49800;
  color: var(--White, #FFF);
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  text-transform: uppercase;
  border: none;
}

.successContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.successText{
  color: #00AE1C;
text-align: center;
font-family: "Work Sans";
font-size: 17px;
font-style: normal;
font-weight: 700;
line-height: 26px; /* 136.842% */
padding-top: 30px;
}

@media screen and (max-width: 768px) {
  .configuratorModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .configuratorModalContent {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #EFECE2;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 140px;
    align-items: flex-end;
    width: 90%;
    height: 90vh;

  }

  .configuratorModalNavigation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 100%;
    /* overflow-x: scroll; */
    width: 100%;
    height: fit-content;

  }

  .configuratorModalNavItem {
    display: flex;
    padding: 17px 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    width: 100%;
    max-width: 1020px;
    border-bottom: 1px solid var(--Divider-Color, #E4E4E4);
  }

  .configuratorModalNavItem p {
    color: var(--Cod-Gray, #121212);

    /* Heading 6 */
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
  }

  .configuratorModalNavItem img {
    width: 30px;
    height: 30px;
  }

  .configuratorModalHidden {
    display: none;
  }

  .dealerCodeContainer {
    padding: 80px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;

  }

  .dealer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    height: 100%;
  }

  .dealerCodeContent {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
  }

  .dealerCodeText {
    width: 100%;
    font-size: 14px;
    line-height: 25px;
    /* 178.571% */
  }

  .generateDealerCode {
    color: var(--Cod-Gray, #121212);
    text-align: center;

    /* Heading 4 */
    font-family: Bebas Neue;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 125% */
    letter-spacing: 0.48px;
  }

  .configuratorModalNavItem:hover p {
    color: var(--Orange-Peel, #F49800);

  }

  .dealerCodeText span {
    font-size: 14px;
    line-height: 25px;
  }

  .dealerCode {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    align-self: stretch;

  }

  .code {
    font-size: 26px;
    line-height: 26px;
    /* 100% */
    letter-spacing: 0.52px;
  }




  .codeForm {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }

  .title {
    color: var(--Cod-Gray, #121212);
    text-align: center;

    /* Heading 4 */
    font-family: Bebas Neue;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 125% */
    letter-spacing: 0.48px;
    margin-top: unset;
  }

  .dealerCodeFormContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 80px 10px;
    width: 100%;


  }

  .dealerCodeFormContainer input {
    display: flex;
    padding: 10px 23px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 40px;
    border: 1px solid var(--Divider-Color, #E4E4E4);
    margin-top: -15px;
  }

  .dealerCodeFormContainer span {
    font-size: 14px;
    line-height: 20px;
    /* 142.857% */
    width: 100%;
  }
}