.imgWrapper img {
    background-color: #f7f6f1;
}

.goback {
  color: var(--Cod-Gray, #121212);
  text-align: center;
  font-family: Work Sans Bold;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid var(--Divider-Color, #e4e4e4);
  width: 146px;
  text-decoration: none;
}

.loader {
  width: 50px;
  height: 50px;
  opacity: 0.4;
}

.moduleItem.dragging {
  opacity: 0.5;
}

.succesModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.successWrapper {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #fff;

  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* gap: 180px; */
  align-items: flex-end;

}

.successContainer {
  padding: 60px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
}

.successText {
  color: var(--Cod-Gray, #121212);
  text-align: center;

  /* Heading 3 */
  font-family: "Bebas Neue";
  font-size: 2.1875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.625rem;
  /* 120% */
}

.successDescription {
  color: var(--Tundora, #4D4D4D);
  text-align: center;

  /* Paragraph */
  font-family: "Work Sans";
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem;
  width: 80%;
  /* 173.333% */
}

.alertDescription {
  color: var(--Tundora, #4D4D4D);
  text-align: center;

  /* Paragraph */
  font-family: "Work Sans";
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem;
  width: 100%;
  margin: 0px 15px;
  /* 173.333% */
}

.alertButton {
  display: flex;
  padding: 8px 40px;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: var(--Orange-Peel, #F49800);
  color: var(--White, #FFF);
  font-family: "Bebas Neue";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 100% */
  letter-spacing: 0.48px;
  border: none;

}

.alertButton:hover {
  background: var(--Orange-Peel, #e39001);
  cursor: pointer;
}

.buttons {
  display: flex;
  width: 100%;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
}

.print_topview {
  display: flex;
  gap: 10px;
  align-items: center;
}

.print_topview p {
  border-radius: 50px;
  background: var(--Cod-Gray, #121212);
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-transform: uppercase;
  cursor: pointer;
  color: #efece2;
}

.print_topview p:hover {
  background: var(--Orange-Peel, #F49800);
  color: #121212;
  font-weight: 600;
}

.languageButton {
  background-color: #F49800 !important;
  color: #000 !important;
  font-weight: 600 !important;
  border-radius: 99999px !important;
  padding: 10px !important;
}

.languageButton:hover {
  background-color: #121212 !important;
  color: #efece2 !important;
}

.cancelButton {
  border-radius: 50px;
  background: var(--Cod-Gray, #121212);
  display: flex;
  padding: 8px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-transform: uppercase;
  cursor: pointer;
  color: #efece2;
  width: fit-content;
  margin: 0 auto;
  margin-top: 15px;
}

.print {
  border-radius: 50px;
  border: 1px solid var(--Divider-Color, #cdcdcd);
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  background-color: #e8e5db;

}

.print:hover {
  filter: invert();
}

.configurator {
  width: 70%;
  box-sizing: border-box;
}

.cabinets {
  width: 27%;
}

.configuratorWrapper {
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 0.03) 0%,
      rgba(0, 0, 0, 0.03) 100%),
    var(--White, #efece2);
  height: 100vh;
  padding-top: 30px;
  padding-inline: 30px;
  display: flex;
  justify-content: space-between;
}

.modules {
  border: 2px solid var(--Divider-Color, #efece2);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 80vh;
  margin-top: 35px;
  border-radius: 1px;
  padding: 80px;
  border-radius: 8px;
}


.moduleItem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  cursor: grab;
}

.informationText {
  font-size: 24px;
  opacity: 0.4;
}

.moduli {
  transition: 0.4s;
  opacity: 1;
  position: relative;
  width: 100%;
}

.removeIcon {
  position: absolute;
  display: none;
  width: 20px !important;
  height: 20px !important;
  z-index: 2;
  cursor: pointer;

}

.insertIcon {
  position: absolute;
  display: none;
  left: -5px;
  width: 20px !important;
  height: 20px !important;
  z-index: 2;
  cursor: pointer;

}

.modules .moduleItem:hover .moduli {
  opacity: 0.4;
}

.modules .moduleItem:hover .removeIcon {
  display: flex;
}

.modules .moduleItem:hover .insertIcon {
  display: flex;

}

.cabinets {
  border-radius: 8px;
  border-radius: 8px;
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0.5) 100%),
    #efece2;
}

.cabinetsTitle {
  color: var(--Cod-Gray, #121212);
  font-family: Work Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  border-radius: 20px 20px 0px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
  display: flex;
  width: 200px;
  padding: 16px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  margin-left: 50%;
  transform: translateX(-50%);
}

.module h6 {
  color: var(--Cod-Gray, #121212);
  font-family: Work Sans Bold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-top: 10px;
  line-height: 24px;
}

.module img {
  width: 100%;
}

.wrapper {
  display: flex;
  gap: 5%;
  padding: 20px;
  flex-wrap: wrap;
  overflow-y: scroll;
  height: 80%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.wrapper::-webkit-scrollbar {
  display: none;
}

.module {
  width: 47.5%;
  cursor: pointer;
}

.module p {
  color: var(--Tundora, #4d4d4d);
  font-family: Work Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin-top: 5px;
  line-height: 20px;
}

.parts-inquiry {
  display: flex;
  padding: 30px 20px 0px 20px;
  align-items: flex-start;
  gap: 10px;
  border-top: 1px solid var(--Divider-Color, #efece2);
  border-radius: 8px;
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0.5) 100%),
    #efece2;
}

.parts-inquiry .white {
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 8px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  color: var(--Cod-Gray, #121212);
  text-align: center;
  font-family: Work Sans Bold;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  cursor: pointer;
}

.parts-inquiry .black {
  border-radius: 50px;
  background: var(--Cod-Gray, #121212);
  display: flex;
  padding: 8px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  color: var(--White, #efece2);
  text-align: center;
  font-family: Work Sans Bold;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  cursor: pointer;
}

.cabinetSelectionModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.cabinetsModal {
  border-radius: 8px;
  background: #efece2;
  width: 80vw;
  height: 80vh;
}

.insertModule h6 {
  color: var(--Cod-Gray, #121212);
  font-family: Work Sans Bold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-top: 10px;
  line-height: 24px;
}

.insertModule img {
  width: 100%;
}

.insertModule {
  width: 20%;
  cursor: pointer;
}

.insertModule p {
  color: var(--Tundora, #4d4d4d);
  font-family: Work Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin-top: 5px;
  line-height: 20px;
}

.selectWrapper {
  display: flex;
  gap: 5%;
  padding: 60px;
  flex-wrap: wrap;
  overflow-y: scroll;
  height: 82%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 9999;
}

.counterTopItem {
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.countertopImage {
  width: 100%;
  height: auto;
}

.addCounterTopButton,
.removeCounterTopButton {
  display: none;
}

.moduileItem {
  display: flex;
  flex-direction: row;
}

.heightLineWrapper {
  display: flex;
  flex-direction: row;
  width: fit-content;
  justify-content: space-between;
  align-self: stretch;
  padding-bottom: 51px;
  padding-right: 15px;
}

.heightLineOrientation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.elementsContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: fit-content;
  margin-top: 35px;
  border-radius: 1px;
  /* outline: solid ; */

}

.heightLine {
  width: 2px;
  height: 100%;
  background: #808080;

}

.heightLineNumbers {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.heightLineOrientation .segStart,
.heightLineOrientation .segEnd {
  width: 12px;
  height: 2px;
  background: #808080;
}



.lineWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.segStart,
.segEnd {
  width: 2px;
  height: 12px;
  background: #808080;
}

.line {
  width: 100%;
  height: 2px;
  background: #808080;
}

.segWidht:first-child {
  opacity: 1;
}

.segWidht {
  opacity: 0;
}

.numbersWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% + 18px);
}

@media screen and (max-width: 768px) {
  .goback {
    color: var(--Cod-Gray, #121212);
    text-align: center;
    font-family: Work Sans Bold;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
    display: flex;
    padding: 10px 14px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    border: 1px solid var(--Divider-Color, #e4e4e4);
    width: unset;
    text-decoration: none;
  }

  .goback span {
    display: none;
  }

  .buttons {
    display: flex;
    width: 100%;
    margin-top: 10px;
    align-items: center;
    justify-content: space-between;
  }

  .print_topview {
    width: 73%;
    display: flex;
    gap: 5px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .print_topview p {
    border-radius: 50px;
    background: var(--Cod-Gray, #121212);
    display: flex;
    padding: 6px 14px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-transform: uppercase;
    cursor: pointer;
    color: #efece2;
    text-align: center;
    font-family: "Work Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 150% */
  }

  .print {
    border-radius: 50px;
    border: 1px solid var(--Divider-Color, #e4e4e4);
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }

  .configurator {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10%;
  }

  .cabinets {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0.5) 100%),
      #efece2;
  }

  .configuratorWrapper {
    background: linear-gradient(0deg,
        rgba(0, 0, 0, 0.03) 0%,
        rgba(0, 0, 0, 0.03) 100%),
      var(--White, #efece2);
    height: 100vh;
    padding-top: 20px;
    padding-inline: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .modules {
    border: 2px solid var(--Divider-Color, #e4e4e4);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: #fff;
    height: 60vh;
    margin-top: 20px;
    border-radius: 1px;
    padding: 20px;
    border-radius: 8px;
  }

  .elementsContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100% !important;
    border-radius: 1px;
    overflow-x: scroll;
  }

  .informationText {
    font-size: 14px;
  }

  .moduleItem {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100% !important;
    height: auto !important;
  }

  .moduli {
    transition: 0.4s;
    opacity: 1;
    position: relative;
  }

  .removeIcon {
    position: absolute;
    display: none;
    width: 20px !important;
    height: 20px !important;
  }

  .insertIcon {
    position: absolute;
    display: none;
    right: -5px;
    width: 20px !important;
    height: 20px !important;
    z-index: 9;
  }

  .modules .moduleItem:hover .moduli {
    opacity: 0.4;
  }

  .modules .moduleItem:hover .removeIcon {
    display: flex;
  }

  .modules .moduleItem:hover .insertIcon {
    display: flex;
  }

  .cabinets {
    border-radius: 8px;
    background: linear-gradient(0deg,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0.5) 100%),
      #efece2;
  }

  .cabinetsTitle {
    margin-top: 10px;
    color: var(--Cod-Gray, #121212);
    font-family: Work Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    display: flex;
    width: 200px;
    padding: 16px 0px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .module h6 {
    color: var(--Cod-Gray, #121212);
    font-family: Work Sans Bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-top: 10px;
    line-height: 24px;
  }

  .module img {
    width: 100%;
  }

  .wrapper {
    display: flex;
    gap: 5%;
    padding: 20px;
    flex-wrap: wrap;
    overflow-y: scroll;
    height: 80%;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .wrapper::-webkit-scrollbar {
    display: none;
  }

  .module {
    width: 47.5%;
    cursor: pointer;
  }

  .module p {
    color: var(--Tundora, #4d4d4d);
    font-family: Work Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    margin-top: 5px;
    line-height: 20px;
  }

  .parts-inquiry {
    display: flex;
    padding: 30px 20px 0px 20px;
    align-items: flex-start;
    gap: 10px;
    border-top: 1px solid var(--Divider-Color, #e4e4e4);
    background: linear-gradient(0deg,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0.5) 100%),
      #efece2;
  }

  .parts-inquiry .white {
    border-radius: 50px;
    border: 1px solid rgba(0, 0, 0, 0.6);
    display: flex;
    padding: 8px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    color: var(--Cod-Gray, #121212);
    text-align: center;
    font-family: Work Sans Bold;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
    cursor: pointer;
  }

  .parts-inquiry .black {
    border-radius: 50px;
    background: var(--Cod-Gray, #121212);
    display: flex;
    padding: 8px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    color: var(--White, #efece2);
    text-align: center;
    font-family: Work Sans Bold;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
    cursor: pointer;
  }

  .cabinetSelectionModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    z-index: 99999;
    align-items: center;
  }

  .cabinetsModal {
    border-radius: 8px;
    background: linear-gradient(0deg,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0.5) 100%),
      #efece2;
    width: 90%;
    height: 80vh;
  }

  .insertModule h6 {
    color: var(--Cod-Gray, #121212);
    font-family: Work Sans Bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-top: 10px;
    line-height: 24px;
  }

  .insertModule img {
    width: 100%;
  }

  .insertModule {
    width: calc(45% - 20px);
    cursor: pointer;
  }

  .insertModule p {
    color: var(--Tundora, #4d4d4d);
    font-family: Work Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    margin-top: 5px;
    line-height: 20px;
  }

  .selectWrapper {
    display: flex;
    gap: 5%;
    padding: 20px;
    flex-wrap: wrap;
    overflow-y: scroll;
    height: 80%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .heightLine {
    width: 2px;
    background: #808080;

    bottom: 0;
  }


  .counterTopItem {
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .countertopImage {
    width: 100%;
    height: auto;
  }


  .addCounterTopButton,
  .removeCounterTopButton {
    display: none;
  }


  .heightLineWrapper {
    display: none;
  }
}