.configFormErrorMsg{
    color: red;
text-align: center;
font-family: "Work Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 26px; /* 136.842% */
}

body{
    background-color:#e8e5db;
}