.contact-form-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  padding: 40px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.contact-form-modal.open {
  display: flex;
}

.modal-content {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #EFECE2;

  padding: 100px;
  border-radius: 8px;
  width: fit-content;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 50px;
  max-width: 1220px;

}

#close {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}


.contact-form-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.contact-form-header .bigTitle {
  color: var(--Cod-Gray, #121212);

  /* Heading 2 */
  font-family: Bebas Neue;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  /* 110% */
}

.contact-from-description {
  max-width: 530px;
  width: 100%;
  color: var(--Tundora, #4D4D4D);

  /* Paragraph */
  font-family: Work Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 173.333% */
}

.form-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  row-gap: 20px;
}

.form-container .form-input {
  width: calc(50% - 20px);
  display: flex;
  padding: 10px 23px;
  align-items: center;
  gap: 10px;
  color: var(--Cod-Gray, #121212);

  /* Paragraph */
  font-family: Work Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 173.333% */
  border-radius: 40px;
  border: 1px solid var(--Divider-Color, #E4E4E4);
}

.contact-form-bottom {
  margin-top: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
}

.sendForm {
  display: flex;
  padding: 10px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #F49800;
  color: var(--White, #FFF);
  text-align: center;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  text-transform: uppercase;
  border: none;
}

.contact-form-bottom label {
  color: var(--Tundora, #4D4D4D);

  /* Paragraph */
  font-family: Work Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 173.333% */
  max-width: 720px;

}

.checkbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;


}

.checkbox input {
  margin-top: 6px;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .contact-form-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    padding: 10px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;

  }

  .contact-form-modal.open {
    display: flex;
  }

  .modal-content {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #EFECE2;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 20px;
    max-width: unset;
    width: 100%;
    height: 90%;
    overflow-y: scroll !important;
  }

  .modal-content section{
    overflow-y: scroll;
  }
  #close {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
  }

  

  .contact-form-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .contact-form-header .bigTitle {
    font-size: 32px;
    line-height: 36px;
    /* 112.5% */
  }

  .contact-from-description {
    max-width: 530px;
    width: 100%;
    font-size: 14px;
    line-height: 25px;
    /* 178.571% */
  }

  .form-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    row-gap: 10px;

  }

  .form-container .form-input {
    width: 100%;
    display: flex;
    padding: 10px 23px;
    align-items: center;
    gap: 10px;
    color: var(--Cod-Gray, #121212);

    /* Paragraph */
    font-family: Work Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 173.333% */
    border-radius: 40px;
    border: 1px solid var(--Divider-Color, #E4E4E4);
  }

  .contact-form-bottom {
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    flex-direction: column;

  }

  .sendForm {
    display: flex;
    padding: 10px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: #F49800;
    color: var(--White, #FFF);
    text-align: center;
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    text-transform: uppercase;
    border: none;
  }

  .contact-form-bottom label {
    font-size: 14px;
    line-height: 25px;
    /* 178.571% */

  }

  .checkbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;


  }

  .checkbox input {
    margin-top: 6px;
    margin-right: 10px;
  }
}